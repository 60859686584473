/** Import react/libraries section **/
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswers } from '../../store/slices/survey-slice';

/** Import styles section **/
import './MultipleQuestionStyles.scss';
import MUICheckBox from '../inputs/MUICheckBox';
import { FormControlLabel } from '@mui/material';

/** Import resources section **/
const MultipleQuestion = ({ data }) => {
  let dispatch = useDispatch();
  let questionAnwers = useSelector((store) => store.survey.answers[data?.id]);

  const handleOnInputChange = (answerId, checked) => {
    // const { id, value } = data;

    const answer = answerId.toString();

    const newAnswers = questionAnwers ? [...questionAnwers] : [];

    if (checked && !newAnswers?.includes(answer)) {
      newAnswers.push(answer);
    }

    if (!checked && newAnswers?.includes(answer)) {
      const foundAnswerIndex = questionAnwers?.findIndex(
        (element) => element === answer
      );

      if (foundAnswerIndex > -1) {
        newAnswers.splice(foundAnswerIndex, 1);
      }
    }

    dispatch(
      setAnswers({
        id: data?.id,
        question_answers: newAnswers
      })
    );
  };

  return (
    <div className="multipleQuestion">
      {data?.answers.map((answer, index) => (
        <FormControlLabel
          label={answer?.title}
          key={'multiple_' + data?.id + '_' + index}
          control={
            <MUICheckBox
              label={answer?.title}
              checked={questionAnwers?.includes(answer?.id.toString())}
              onChange={(checked) => {
                handleOnInputChange(answer?.id, checked);
              }}
              className="multipleQuestion_checkbox"
            />
          }
        />
      ))}
    </div>
  );
};

MultipleQuestion.propTypes = {
  data: PropTypes.object.isRequired
};

export default MultipleQuestion;
