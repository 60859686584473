/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

/** Import helpers section **/
import { CHECK_OUT_SURVEY_ID } from '../constants';

/** Import services section **/
import { getRegistrationSurveysService } from '../../../api/services/registration-survey-services';
import { saveAnswersSurvey } from '../../../api/services/survey-services';

const useActions = () => {
  const reservationIdStored = useSelector(
    (store) => store.survey?.reservation_id
  );
  const [surveyData, setSurveyData] = useState({});
  const [reservationData, setReservationData] = useState({});
  const [answeredSurvey, setAnsweredSurvey] = useState(false);

  const getRegistrationSurvey = useCallback(
    (
      pageReservationId,
      reservationNumber,
      clientName,
      checkIn,
      checkOut,
      roomsCount
    ) => {
      let params = {
        reservation_id: pageReservationId,
        reservation_number: reservationNumber,
        quiz_id: CHECK_OUT_SURVEY_ID,
        name: clientName,
        rooms: roomsCount,
        stay_dates: checkIn + ' / ' + checkOut
        // check out quiz id
      };
      getRegistrationSurveysService(params)
        .then((response) => {
          if (response?.survey) setSurveyData(response.survey);
          if (response?.answered) setAnsweredSurvey(true);
          let complementData = {
            name: response?.name,
            phone: response?.phone,
            reason_trip: response?.reason_trip,
            rooms: response?.rooms,
            stay_dates: response?.stay_dates,
            reservation_number: response?.reservation_number
          };
          setReservationData(complementData);
        })
        .catch((err) => {
          if (err?.response_status) toast.error("Can't load information");
          console.error('Error in registration: ', err);
        });
    },
    []
  );

  const saveSurvey = useCallback(
    (survey, pageReservationId, registrationSurveyId) => {
      saveAnswersSurvey(survey, pageReservationId, registrationSurveyId)
        .then((response) => {
          toast.success('Encuesta guardada correctamente');
          getRegistrationSurvey(reservationIdStored);
        })
        .catch((error) => {
          console.error('no se ha podido guardar la encuesta', error);
        });
    },
    [getRegistrationSurvey, reservationIdStored]
  );

  return {
    surveyData,
    getRegistrationSurvey,
    saveSurvey,
    answeredSurvey,
    reservationData
  };
};

export default useActions;
