/** Import react/libraries section **/
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

/** Import styles section **/
import './NumericInputStyles.scss';
import { useState } from 'react';

/** Import resources section **/
const NumericInput = ({ numericAnswer, questionTitle, onInputChange }) => {
  const [numericValue, setNumericValue] = useState(numericAnswer);

  const handleOnAdd = () => {
    // added limit 10
    if (numericValue < 10) {
      const value = numericValue + 1;
      setNumericValue(value);
      onInputChange(value);
    }
  };

  const handleOnSubstract = () => {
    if (numericValue === 0) return;
    const value = numericValue - 1;
    setNumericValue(value);
    onInputChange(value);
  };

  return (
    <div className="numericInput">
      {questionTitle}
      <div className="numericInput_sings">
        <RemoveIcon
          onClick={handleOnSubstract}
          className="numericInput_sings_sub"
        />
        {numericValue}
        <AddIcon onClick={handleOnAdd} className="numericInput_sings_add" />
      </div>
    </div>
  );
};

NumericInput.propTypes = {
  numericAnswer: PropTypes.number.isRequired,
  questionTitle: PropTypes.string,
  onInputChange: PropTypes.func.isRequired
};

export default NumericInput;
