import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
  filterBy: {
    width: '100%',
    minWidth: '100px'
  }
}));

const FilterBy = ({ id, name, onChange, ...otherProps }) => {
  const classes = useStyles();

  const handleOnChange = (event) => {
    if (onChange) onChange(event.target.value);
  };

  return (
    <div id={id ? id : 'filterby'}>
      <TextField
        label="Filtrar por..."
        className={classes.filterBy}
        fullWidth
        focused
        onChange={handleOnChange}
        {...otherProps}
      />
    </div>
  );
};

export default FilterBy;
