/** Import react/libraries section **/
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswers } from '../../store/slices/survey-slice';

/** Import styles section **/
import './SingleQuestionStyles.scss';

/** Import resources section **/
 const SingleQuestion = ({ data, onAnswered, fromRuleQuestions }) => {
  let dispatch = useDispatch();
  let surveyAnwer = useSelector((store) => store.survey.answers[data?.id]?.[0]);

  const handleOnInputChange = (value) => {
    dispatch(
      setAnswers({
        id: data?.id,
        question_answers: [value?.toString()]
      })
    );
    onAnswered(data?.id);
  };

  const singleQuestionId = surveyAnwer ? parseInt(surveyAnwer) : null;

  return (
    <div className="singleQuestion">
      {!!data?.title && fromRuleQuestions ? (
        <>
          <div className="surveySection_title">{data?.title}</div>
        </>
      ) : (
        data?.title
      )}
      {data?.answers.map((answer, index) => (
        <div
          key={'singleQuestion_' + index}
          onClick={() => handleOnInputChange(answer?.id)}
          className={
            'singleQuestion_option' +
            (singleQuestionId === parseInt(answer?.id) ? ' selected' : '')
          }
        >
          {answer?.title}
        </div>
      ))}
    </div>
  );
};

SingleQuestion.propTypes = {
  data: PropTypes.object.isRequired
};

export default SingleQuestion;
