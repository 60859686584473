/** Import react/libraries section **/
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

/** Import helpers section **/
import { isValidEmail } from '../../utils/validations.js';
import { LOGIN_ROUTE } from '../../config/URL_ROUTES';

/** Import styles section **/
import './ChangePasswordRequestStyles.scss';

/** Import resources section **/
import { Button, Paper } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

/** Import component section **/
import usePageActions from './hooks/usePageActions.jsx';
import ConfirmModal from '../../components/ConfirmModal';
import MUITextField from '../../components/inputs/MUITextField';

const ChangePasswordPage = () => {
  const [email, setEmail] = useState(null);
  const [validEmail, setValidEmail] = useState(false);

  const { requested, successRequest, loading, changePassword, onResetRequest } =
    usePageActions();

  let history = useHistory();

  const handleOnSubmit = (data) => {
    changePassword(email);
  };

  const handeOnEmailChange = (data) => {
    setEmail(data.value);
    const isValid = isValidEmail(data.value);
    setValidEmail(isValid);
  };

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };

  const requestView = () => {
    return (
      <>
        <div className="changepasswordreq_container_form">
          <MUITextField
            label="Ingresa tu dirección de correo"
            id="email"
            name="email"
            value={email}
            type="text"
            onChange={handeOnEmailChange}
          />
        </div>
        <Button
          className="changepasswordreq_container_button"
          onClick={handleOnSubmit}
          disabled={!validEmail}
        >
          {loading === true ? 'Solicitando' : 'Solicitar'}
        </Button>
        <div className="changepasswordreq_container_success_link ">
          <Link to="/">
            <ArrowBackIosIcon /> Regresar a login
          </Link>
        </div>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <div className="changepasswordreq_container_error">
          <div className="changepasswordreq_container_error_text">
            Problema inesperado con el sistema.
          </div>
        </div>
        <Button
          className="changepasswordreq_container_button"
          onClick={onResetRequest}
        >
          Reintentar?
        </Button>
      </>
    );
  };

  const viewToShow = () => {
    if (requested && !successRequest) {
      return errorView();
    }

    return requestView();
  };

  return (
    <>
      <div className="changepassword">
        <Paper className="changepasswordreq_container">
          <div className={'changepasswordreq_container_title'}>
            Solicitar cambio de contraseña
          </div>
          {viewToShow()}
        </Paper>
      </div>
      {successRequest && (
        <ConfirmModal
          title={'Password change requested!'}
          message={' You will receive a email to confirm.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default ChangePasswordPage;
