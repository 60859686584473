/** Import helpers section **/
import { createSlice } from '@reduxjs/toolkit';
import { clearLocalStored, saveLocalStorage } from '../../utils/localStorage';
import { LOCAL_STORAGE_SURVEY_NAME } from '../../config/APP_CONFIGS';

const initialState = {
  reservation_id: null,
  answers: {}
};

const surveySlice = createSlice({
  name: 'survey',
  initialState: initialState,
  reducers: {
    setSurvey: (state, { payload: { reservation_id, answers } }) => {
      state.reservation_id = reservation_id;
      state.answers = answers;
    },
    setReservation: (state, { payload: { reservation_id } }) => {
      state.reservation_id = reservation_id;
      const survey = {
        reservation_id,
        answers: state.answers
      };
      saveLocalStorage(LOCAL_STORAGE_SURVEY_NAME, survey);
    },

    setAnswers: (state, { payload: { id, question_answers } }) => {
      const answers = {
        ...state.answers,
        [id]: question_answers
      };
      state.answers = answers;
      const survey = {
        reservation_id: state.reservation_id,
        answers
      };
      saveLocalStorage(LOCAL_STORAGE_SURVEY_NAME, survey);
    },

    clearAnswers: (state) => {
      state.answers = {};
      clearLocalStored(LOCAL_STORAGE_SURVEY_NAME);
    }
  }
});

export default surveySlice.reducer;

export const {
  setSurvey,
  setReservation,
  setAnswers,
  clearAnswers,
  clearRuleQuestionAnswers
} = surveySlice.actions;
