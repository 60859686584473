import request from '../api-connector';

export const getRegistrationSurveysService = ({
  reservation_id,
  quiz_id,
  name,
  rooms,
  stay_dates,
  reservation_number
}) => {
  const serviceDef = {
    url: 'survey/registration',
    method: 'get',
    params: {
      reservation_id: reservation_id,
      quiz_id: quiz_id,
      name: name,
      rooms: rooms,
      stay_dates: stay_dates,
      reservation_number: reservation_number
    }
  };
  return request(serviceDef);
};
