/** Import react/libraries section **/
import PropTypes from 'prop-types';

/** Import styles section **/
import './SurveySectionStyles.scss';
import Question from '../Question';

/** Import resources section **/

const SurveySection = ({ data }) => {
  return (
    <div className="surveySection">
      {!!data?.title && (
        <div className="surveySection_title">{data?.title}</div>
      )}
      <div className="surveySection_content">
        {data?.questions?.map((question, key) => (
          <Question data={question} key={key} />
        ))}
      </div>
    </div>
  );
};

SurveySection.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default SurveySection;
