/** Import react/libraries section **/
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/** Import resources section **/
import { Paper } from '@mui/material';

/** Import styles section **/
import './EmailValidationStyles.scss';

/** Import helpers section **/
import { LOGIN_ROUTE } from '../../config/URL_ROUTES';
import Logos from '../../components/Logos';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import ConfirmModal from '../../components/ConfirmModal';

const EmailValidationPage = () => {
  const { confirmed, finished, getValidation } = usePageActions();

  const history = useHistory();
  let pageParams = useParams();
  let token = pageParams.token;

  useEffect(() => {
    getValidation(token);
  }, [getValidation, token]);

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };

  const initialView = () => {
    return (
      <>
        <span className="emailvalidation_container_title">
          Verificando Email
        </span>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <span className="emailvalidation_container_title error">
          Error en la verificación.
        </span>
      </>
    );
  };

  const viewToShow = () => {
    if (finished && !confirmed) {
      return errorView();
    }

    return initialView();
  };

  return (
    <>
      <div className="emailvalidation">
        <Paper className="emailvalidation_container">
          {viewToShow()}
          <div className="emailvalidation_container_logos">
            <Logos />
          </div>
        </Paper>
      </div>

      {confirmed && (
        <ConfirmModal
          title={'Email validated successfully!'}
          message={
            'Your email was verified. In order to activate your account, please wait until the U&P administrator authorizes your account. You will receive an email with your authorization in 24-48 hours.'
          }
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default EmailValidationPage;
