/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

/** Import helpers section **/
import './usersStyles.scss';

/** Import component section **/
import Modal from '../../components/Modal';
import UserModal from './components/UserModal';
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import usePageActions from './hooks/usePageActions';

const userModel = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_conf: '',
  role_id: null,
  active: 1
};

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const { roles, loading, getRoles, getUsers, deleteUser, changeActiveUser } =
    usePageActions();

  useEffect(() => {
    getRoles();
    getUsers(setUsers, setOriginalData);
  }, [getRoles, getUsers]);

  const handleOnUpdateList = () => {
    getUsers();
  };

  const handleOnModalClose = () => {
    setSelectedUser(null);
    setModalMode(null);
  };

  const handleOnAddUser = () => {
    setSelectedUser(Object.assign({}, userModel));
    setModalMode('add');
  };

  const handleOnEditUser = (row) => {
    setSelectedUser(row);
    setModalMode('edit');
  };

  const handleOnChangePassword = (row) => {
    setSelectedUser(row);
    setModalMode('password');
  };

  const handleOnDeleteUser = (row) => {
    setSelectedUser(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteUser(selectedUser.id);
    setSelectedUser(null);
    setModalMode(null);
  };

  const handleOnActiveConfirm = () => {
    changeActiveUser(selectedUser.id);
    setSelectedUser(null);
    setModalMode(null);
  };

  const handleOnActiveChange = (row) => {
    setSelectedUser(row);
    setModalMode('active');
  };

  const handleOnFilterUsers = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.first_name?.toString().toLowerCase();
        const field2 = item.last_name?.toString().toLowerCase();
        const field3 = item.email?.toString().toLowerCase();
        const field4 = item.role_title?.toString().toLowerCase();

        return (
          field1.includes(filteringText) ||
          field2.includes(filteringText) ||
          field3.includes(filteringText) ||
          field4.includes(filteringText)
        );
      });
    }

    setUsers(filteredItems);
  };

  const ActiveCell = ({ row }) => {
    if (row.active)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active users_active"
          onClick={() => handleOnActiveChange(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon users_active"
        onClick={() => handleOnActiveChange(row)}
      />
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <LockIcon
          className="g-row-action-button"
          onClick={() => handleOnChangePassword(row)}
        />
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditUser(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handleOnDeleteUser(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: (row) => row?.first_name,
        sortable: true,
        center: true
      },
      {
        name: 'Apellido',
        selector: (row) => row?.last_name,
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: (row) => row?.email,
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Rol',
        selector: (row) => row?.role_title,
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: (row) => row?.active,
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  const editModalMode =
    modalMode === 'edit' || modalMode === 'add' || modalMode === 'password';

  return (
    <>
      <PageTitle title="Usuarios" />
      <Paper className="g-page-header users_header">
        <div className="users_header_filterby">
          <FilterBy onChange={handleOnFilterUsers} />
        </div>
        <Button onClick={handleOnAddUser} startIcon={<AddCircleIcon />}>
          Agregar nuevo usuario
        </Button>
      </Paper>

      <DataTable data={users} columns={tableColumns} loading={loading} />

      {selectedUser && editModalMode && (
        <UserModal
          userData={selectedUser}
          modalMode={modalMode}
          roles={roles}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}

      {selectedUser && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Usuario"
        >
          {'¿Estas seguro que quieres borrar el usuario ' +
            selectedUser.email +
            '?'}
        </Modal>
      )}

      {selectedUser && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedUser?.active === 0
              ? 'Activar usuario?'
              : 'Desactivar usuario'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedUser?.active === 0 ? 'Activar' : 'Desactivar') +
            ' el usuario ' +
            selectedUser.email +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default UsersPage;
