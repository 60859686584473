/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import {
  getRolesService,
  getUsersService,
  deleteUserService,
  changeActiveService
} from '../../../api/services/users-services';

const useActions = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsers = useCallback((setUsers, setOriginalData) => {
    setLoading(true);
    getUsersService()
      .then((response) => {
        if (response?.users) {
          setUsers(response?.users);
          setOriginalData(response?.users);
        }
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getRoles = useCallback(() => {
    setLoading(true);
    getRolesService()
      .then((response) => {
        if (response?.roles) setRoles(response?.roles);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deleteUser = (id) => {
    deleteUserService({ id })
      .then(() => {
        getUsers();
        toast.success('Usuario borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el usuario.');
      });
  };

  const changeActiveUser = (id) => {
    changeActiveService({ id })
      .then(() => {
        getUsers();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      });
  };

  return {
    roles,
    getRoles,
    getUsers,
    deleteUser,
    changeActiveUser,
    loading
  };
};

export default useActions;
