/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import syles section **/
import './UserModalStyles.scss';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import resources section **/
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

/** Import components section  **/
import useModalActions from '../hooks/useModalActions';
import { isValidEmail } from '../../../../utils/validations';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';

const UserModal = ({ userData, modalMode, roles, onClose, onUpdateList }) => {
  const [user, setUser] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const { changePassword, updateUser, createUser } = useModalActions();

  useEffect(() => {
    const newUSer = Object.assign(
      { password: null, password_conf: null },
      userData
    );
    setUser(newUSer);
  }, [userData]);

  const handleOnSave = () => {
    if (modalMode === 'password') {
      changePassword(user, onClose, onUpdateList);
    } else if (user.id) {
      updateUser(user, onClose, onUpdateList);
    } else {
      createUser(user, onClose, onUpdateList);
    }
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setUser((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!user) return;
    let validated = true;
    if (modalMode === 'edit' || 'add') {
      const email = user?.email?.trim();
      const first_name = user?.first_name?.trim();
      const last_name = user?.last_name?.trim();
      const role_id = user?.role_id;
      if (
        first_name === '' ||
        last_name === '' ||
        !role_id ||
        !isValidEmail(email)
      ) {
        validated = false;
      }
    }

    if (modalMode === 'password' && !!user?.id) {
      if (!user?.password && !user?.password_conf) {
        validated = false;
      }
      const password = user?.password?.trim();
      const password_conf = user?.password_conf?.trim();
      if (
        password === '' ||
        password_conf === '' ||
        password !== password_conf
      ) {
        validated = false;
      }
    }
    setDataComplete(validated);
  }, [user, modalMode]);

  const title =
    modalMode === 'password'
      ? 'Cambiar contraseña'
      : user?.id
      ? 'Editar usuario'
      : 'Agregar usuario';

  return (
    <ModalComponent
      open={true}
      title={title}
      element={user}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!dataComplete}
    >
      <div className="usermodal">
        {(modalMode === 'edit' || 'add') && (
          <>
            <FormControlLabel
              className="usermodal_active"
              control={
                <Switch
                  label="Activo"
                  checked={user?.active === 1}
                  onChange={(event) => onSwitchChange(event)}
                  name="active"
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                />
              }
              label="Activo"
            />
            <MUITextField
              label="Nombre"
              defaultValue={user?.first_name}
              type="text"
              name="first_name"
              onChange={(data) => inputChange(setUser, data)}
            />
            <MUITextField
              label="Apellido"
              defaultValue={user?.last_name}
              type="text"
              name="last_name"
              onChange={(data) => inputChange(setUser, data)}
            />
            <MUITextField
              label="Email"
              defaultValue={user?.email}
              type="text"
              name="email"
              onChange={(data) => inputChange(setUser, data)}
            />
            <MUISelect
              label="Rol"
              defaultValue={user?.role_id}
              items={roles}
              idSelector="id"
              selector="title"
              noSelectedLabel="Select"
              name="role_id"
              id="role_id"
              onChange={(data) => inputChange(setUser, data)}
            />
          </>
        )}

        {(modalMode === 'password' || (modalMode === 'user' && !user?.id)) && (
          <>
            <MUITextField
              label="Contraseña"
              defaultValue={user?.password}
              type="password"
              name="password"
              error={user?.password !== user?.password_conf}
              onChange={(data) => inputChange(setUser, data)}
            />

            <MUITextField
              label="Confirmar contraseña"
              defaultValue={user?.password_conf}
              type="password"
              name="password_conf"
              error={user?.password !== user?.password_conf}
              onChange={(data) => inputChange(setUser, data)}
            />
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default UserModal;
