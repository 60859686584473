import request from '../api-connector';

export const saveAnswersSurvey = (
  survey,
  pageReservationId,
  registrationSurveyId
) => {
  const serviceDef = {
    url: 'survey/save',
    method: 'post',
    params: {
      survey: survey,
      reservation_id: pageReservationId,
      quiz_id: registrationSurveyId
    }
  };
  return request(serviceDef);
};
