/** Import helpers section **/
import { useState } from 'react';

/** Import services section **/
import { emailValidationService } from '../../../api/services/signups-services';

const usePageActions = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [finished, setFinished] = useState(false);

  const getValidation = (token) => {
    emailValidationService({ token })
      .then((response) => {
        setConfirmed(true);
      })
      .catch((err) => {
        const status = err.response_status;
        if (status === 400) {
          console.error('error token');
        }
        console.error('Error getting validation: ', err);
      })
      .finally(() => {
        setFinished(true);
      });
  };

  return {
    confirmed,
    finished,
    getValidation
  };
};

export default usePageActions;
