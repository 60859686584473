import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LockIcon from '@mui/icons-material/Lock';

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
const USER_MENU = 'users';
const CONFIGURATION_MENU = 'configuration';
const PERMISSIONS_MENU = 'permissions';
const PERMISSIONS_CATALOGUE_MENU = 'permissions-catalogue';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  {
    id: USER_MENU,
    label: 'Usuarios',
    url: routes.USERS_ROUTE,
    icon: PersonIcon
  },
  {
    id: CONFIGURATION_MENU,
    label: 'Configuración',
    icon: SettingsIcon,
    items: [
      {
        id: PERMISSIONS_MENU,
        label: 'Permisos por rol',
        url: routes.ROLE_PERMISSIONS_ROUTE,
        icon: AccountTreeIcon
      },
      {
        id: PERMISSIONS_CATALOGUE_MENU,
        label: 'Permisos',
        url: routes.PERMISSIONS_ROUTE,
        icon: LockIcon
      }
    ]
  }
];
export default MENU_ITEMS;
