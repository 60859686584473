/** Import react/libraries section **/
import React from 'react';
import ReactDOM from 'react-dom';

/** Import styles section **/
import theme from './styles/theme';
import './styles/index.scss';

/** Import resources section **/
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';

/** Import helpers section **/
import { ThemeProvider } from '@mui/material/styles';

/** Import component section **/
import App from './App';
import StoreRoot from './store/StoreRoot';

ReactDOM.render(
  <React.StrictMode>
    <StoreRoot>
      <ThemeProvider theme={theme}>
        <LocalizationProvider locale={frLocale} dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </StoreRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals( console.log );
