/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, Redirect, Link } from 'react-router-dom';

/** Import styles section **/
import './LoginStyles.scss';

/** Import resources section **/
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

/** Import component section **/
import usePageActions from './hooks/usePageActions';

/** Import helpers section **/
// import logo from '../../assets/logo_mediatasoft.png';
import { CHANGE_PASSWORD_REQUEST_ROUTE } from '../../config/URL_ROUTES';

const LoginPage = () => {
  const [enterApp, setEnterApp] = useState(false);

  let location = useLocation();

  const { loggedIn, loading, login } = usePageActions();

  useEffect(() => {
    if (loggedIn) setEnterApp(true);
  }, [loggedIn]);
  const handleOnSubmit = ({ username, password }) => {
    login({ username, password });
  };

  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange'
  });

  if (enterApp) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location }
        }}
      />
    );
  } else {
    return (
      <>
        <div className="login">
          <Paper className="login_container">
            <div className="login_container_access">
              <form
                className="login_container_access_form"
                onSubmit={handleSubmit(handleOnSubmit)}
              >
                <TextField
                  label="Email"
                  id="username"
                  name="username"
                  type="text"
                  variant="outlined"
                  {...register('username', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                  })}
                />

                <TextField
                  label="Contraseña"
                  id="password"
                  name="password"
                  type="password"
                  variant="outlined"
                  {...register('password', {
                    required: true,
                    message: 'Contraseña requerida'
                  })}
                />

                <div className="login_container_access_form_button">
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    disabled={!formState.isValid}
                  >
                    {loading === true ? 'Accediendo...' : 'Ingresar'}
                  </Button>
                </div>
              </form>
            </div>

            <div className="login_container_title">
              {/* <img
                src={logo}
                alt="ms-logo"
                className="login_container_title_logo"
              /> */}
              <div className="login_container_title_text">
                Registro de encuestas
                <span className="login_container_title_text_divider" /> Hotel
                Criol
              </div>
            </div>
            <div className="login_container_footer">
              <div className="login_container_footer_recovery">
                Olvidaste tu contraseña?{' '}
                <Link
                  className="login_container_footer-link"
                  to={CHANGE_PASSWORD_REQUEST_ROUTE}
                >
                  Presiona aquí
                </Link>
              </div>
              <Link className="login_container_footer-link" to="/registration">
                Registrarse
              </Link>
            </div>
          </Paper>
        </div>
      </>
    );
  }
};

export default LoginPage;
