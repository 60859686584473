/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import styles section **/
import './RolePermissions.scss';

/** Import helpers section **/

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import DataTable from '../../components/DataTable';
import MUICheckBox from '../../components/inputs/MUICheckBox';
import MUISelect from '../../components/inputs/MUISelect';
import PageTitle from '../../components/PageTitle';

const PermissionsComponent = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPermissions, setSelectedPermissionss] = useState([]);

  const {
    roles,
    permissions,
    loading,
    getRoles,
    getPermissions,
    checkRolePermissions
  } = usePageActions();

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleOnRoleChange = (data) => {
    const roleId = data.value;
    setSelectedRole(roleId);
    getPermissions(roleId);
  };

  const handleOnUpdateList = () => {
    if (!selectedRole) {
      console.error('no selected role meanwhile requesting list');
      return;
    }
    getPermissions(selectedRole);
  };

  const handleOnSelectedChange = (state) => {
    // { allSelected, selectedCount, selectedRows }
    setSelectedPermissionss(state.selectedRows);
  };

  const handleOnSave = () => {
    // { allSelected, selectedCount, selectedRows }
    const rolePermissions = {
      role_id: selectedRole,
      permissions: selectedPermissions
    };
    checkRolePermissions(rolePermissions, handleOnUpdateList);
  };

  const tableColumns = [
    {
      name: 'Id',
      selector: (row) => row?.id,
      sortable: true,
      center: true,
      omit: true
    },
    {
      name: 'Código',
      selector: (row) => row?.code,
      sortable: true,
      center: true
    },
    {
      name: 'Tipo',
      selector: (row) => row?.type_name,
      sortable: true,
      center: true
    }
  ];

  const rowSelectCritera = useMemo(() => {
    return (row) => row.selected;
  }, []);

  return (
    <>
      <PageTitle title="Permisos por rol" />
      <Paper className="g-page-header rolePermissions_header">
        <div className="rolePermissions_header_select">
          <MUISelect
            label="Rol"
            defaultValue={selectedRole}
            items={roles}
            idSelector="id"
            selector="title"
            noSelectedLabel="Select"
            name="role_id"
            onChange={handleOnRoleChange}
          />
        </div>
        <Button onClick={handleOnSave} type="submit">
          {'Guardar permisos'}
        </Button>
      </Paper>
      <DataTable
        data={permissions}
        columns={tableColumns}
        loading={loading}
        selectableRows={true}
        selectableRowsComponent={MUICheckBox}
        selectableRowsNoSelectAll={false}
        selectableRowsHighlight={true}
        onSelectedRowsChange={(state) => handleOnSelectedChange(state)}
        selectableRowSelected={rowSelectCritera}
      />
    </>
  );
};

export default PermissionsComponent;
