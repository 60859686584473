import { combineReducers } from 'redux';
import sessionReducer from './slices/session-slice';
import permissionsReducer from './slices/permissions-slice';
import globalsReducer from './slices/globals-slice';
import surveySlice from './slices/survey-slice';

export default combineReducers({
  session: sessionReducer,
  permissions: permissionsReducer,
  globals: globalsReducer,
  survey: surveySlice
});
