/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import logo from '../../assets/logo_mediatasoft_blanco.png';
/** Import styles section **/
import './HeaderStyles.scss';
import { APP_NAME } from '../../config/APP_CONFIGS';

/** Import helpers section **/

/** Import component section **/

export const HeaderView = ({ userName, onLogout }) => {
  return (
    <div className="mainheader">
      <div className="mainheader_title">
        {/* <img src={logo} alt="ms-logo" className="mainheader_logo" /> */}
        {APP_NAME}
      </div>

      <div className="mainheader_session">
        <PersonIcon className="mainheader_session_usericon" />
        <div className="mainheader_session_profile">
          <div className="mainheader_session_profile_user">{userName}</div>
        </div>
        <div className="mainheader_session_logout">
          <ExitToAppIcon
            className="mainheader_session_logout_icon"
            onClick={onLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderView;
