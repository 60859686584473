/** Import react/libraries section **/
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import styles section **/
import './RegistrationSurvey.scss';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import Survey from '../../components/Survey';
import { useParams } from 'react-router-dom';
import { clearAnswers, setReservation } from '../../store/slices/survey-slice';
import ModalComponent from '../../components/Modal/ModalComponent';

import { REGISTRATION_SURVEY_ID } from './constants';

const RegistrationSurveyPage = () => {
  //route params
  const pageParams = useParams();
  const pageReservationId = pageParams['reservation']
    ? pageParams['reservation']
    : null;

  const userAnswers = useSelector((store) => store.survey.answers);
  const reservationIdStored = useSelector(
    (store) => store.survey?.reservation_id
  );

  const { surveyData, getRegistrationSurvey, saveSurvey, answeredSurvey } =
    usePageActions();

  const dispatch = useDispatch();

  if (reservationIdStored && reservationIdStored !== pageReservationId) {
    dispatch(clearAnswers());
  }
  dispatch(setReservation({ reservation_id: pageReservationId }));

  useEffect(() => {
    getRegistrationSurvey(pageReservationId);
  }, [getRegistrationSurvey, pageReservationId]);

  const handleOnSubmitQuiz = () => {
    saveSurvey(userAnswers, pageReservationId, REGISTRATION_SURVEY_ID);
  };

  return (
    <>
      <div className="registrationSurvey">
        {surveyData && <Survey data={surveyData} onSave={handleOnSubmitQuiz} />}
      </div>

      {answeredSurvey === true && (
        <ModalComponent
          open={true}
          title={'Esta encuesta ha sido contestada'}
          element={null}
          onClose={false}
          onConfirm={null}
          disabled={false}
        >
          El numero de reserva {pageReservationId} ya ha respondido esta
          encuesta.
        </ModalComponent>
      )}
    </>
  );
};
export default RegistrationSurveyPage;
