/** Import react/libraries section **/
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/** Import styles section **/
import './CheckOutSurvey.scss';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import Survey from '../../components/Survey';
import { useParams } from 'react-router-dom';
import { clearAnswers, setReservation } from '../../store/slices/survey-slice';
import ModalComponent from '../../components/Modal/ModalComponent';

import { CHECK_OUT_SURVEY_ID } from './constants';

const CheckOutSurveyPage = () => {
  //route params
  const pageParams = useParams();
  const pageReservationId = pageParams['reservation']
    ? pageParams['reservation']
    : null;
  const reservationNumber = pageParams['reservation_number']
    ? pageParams['reservation_number']
    : null;
  const clientName = pageParams['client_name']
    ? pageParams['client_name']
    : null;
  const checkIn = pageParams['check_in'] ? pageParams['check_in'] : null;
  const checkOut = pageParams['check_out'] ? pageParams['check_out'] : null;
  const roomsCount = pageParams['rooms_count']
    ? pageParams['rooms_count']
    : null;

  const userAnswers = useSelector((store) => store.survey.answers);
  const reservationIdStored = useSelector(
    (store) => store.survey?.reservation_id
  );

  const {
    surveyData,
    getRegistrationSurvey,
    saveSurvey,
    answeredSurvey,
    reservationData
  } = usePageActions();

  const dispatch = useDispatch();

  if (reservationIdStored && reservationIdStored !== pageReservationId) {
    dispatch(clearAnswers());
  }
  dispatch(setReservation({ reservation_id: pageReservationId }));

  useEffect(() => {
    getRegistrationSurvey(
      pageReservationId,
      reservationNumber,
      clientName,
      checkIn,
      checkOut,
      roomsCount
    );
  }, [getRegistrationSurvey, pageReservationId]);

  const handleOnSubmitQuiz = () => {
    saveSurvey(userAnswers, pageReservationId, CHECK_OUT_SURVEY_ID);
  };

  return (
    <>
      <div className="registrationSurvey">
        {surveyData && (
          <Survey
            data={surveyData}
            onSave={handleOnSubmitQuiz}
            reservationData={reservationData}
            checkOutSurvey={true}
          />
        )}
      </div>

      {answeredSurvey === true && (
        <ModalComponent
          open={true}
          title={'Encuesta respondida'}
          element={null}
          onClose={false}
          onConfirm={null}
          disabled={false}
        >
          El numero de reserva {pageReservationId} ya ha respondido esta
          encuesta.
        </ModalComponent>
      )}
    </>
  );
};
export default CheckOutSurveyPage;
