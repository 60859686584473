/** Import react/libraries section **/
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswers, setSurvey } from '../../store/slices/survey-slice';
import Question from '../Question';

/** Import styles section **/
import './RuleQuestionStyles.scss';

/** Import resources section **/
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const RuleQuestion = ({ data }) => {
  let dispatch = useDispatch();

  const questionId = data?.id;
  let userAnswers = useSelector((store) => store.survey.answers);
  let currentReservation = useSelector((store) => store.survey.reservation_id);
  let userQuestionAnswer = userAnswers?.[questionId]?.[0];

  const answerRules = data?.rules?.[userQuestionAnswer];

  const notAnsweredRule = answerRules?.findIndex(
    (item) => !userAnswers[item.id]
  );

  const [showMain, setShowMain] = useState(true); //si existen answers del usuario se va a las hijas no a la principal

  //cambia el index de la pregunta principal a sus hijos si tiene respuestas sino mostrara la hijas no contestadas
  const [ruleQuestionIndex, setRuleQuestionIndex] = useState(0);

  useEffect(() => {
    //no existen respuestas del usuario (primera vez respondiendo) se debera ir a main
    // o cuando no tenga alguna regla de siguientes preguntas se debera ir a main
    const goMain =
      userQuestionAnswer === undefined || !data?.rules?.[userQuestionAnswer]
        ? true
        : false;
    setShowMain(goMain);

    setRuleQuestionIndex(
      notAnsweredRule === -1 ? answerRules?.length - 1 : notAnsweredRule
    );
  }, [data, notAnsweredRule, userQuestionAnswer, answerRules]);

  const handleOnInputChange = (answerId) => {
    const selectedQuestionRules = data?.rules?.[answerId];
    const currentAnswers = structuredClone(userAnswers);

    //si se cambia la respuesta de la pregunta principal, nos aseguramos de que esta tenga rules y
    // entonces iremos a limpiar las respuestas de las subpreguntas
    if (userQuestionAnswer !== answerId && showMain === true && answerRules) {
      answerRules?.map((question) => delete currentAnswers?.[question.id]);

      const survey = {
        reservation_id: currentReservation,
        answers: currentAnswers
      };

      dispatch(setSurvey(survey));
    }

    if (selectedQuestionRules && selectedQuestionRules.length) {
      setShowMain(false);
    }

    dispatch(
      setAnswers({
        id: questionId,
        question_answers: [answerId?.toString()]
      })
    );
  };

  const handleBack = () => {
    if (ruleQuestionIndex === 0) {
      setShowMain(true);
    } else {
      setRuleQuestionIndex(ruleQuestionIndex - 1);
    }
  };

  const handleOnNavigation = (answeredQuestionId) => {
    const answeredQuestion = answerRules?.findIndex(
      (item) => item.id === answeredQuestionId
    );

    if (answeredQuestion < answerRules.length - 1) {
      setRuleQuestionIndex(ruleQuestionIndex + 1);
    }
  };

  if (showMain) {
    return (
      <div className="ruleQuestion">
        <div className="ruleQuestion_main">
          {!!data?.title && (
            <>
              <div className="surveySection_title">{data?.title}</div>
            </>
          )}
          {data?.answers.map((answer, index) => (
            <div
              key={'ruleQuestion_' + index}
              onClick={() => handleOnInputChange(answer?.id)}
              className={
                'ruleQuestion_option' +
                (answer?.id === parseInt(userQuestionAnswer) ? ' selected' : '')
              }
            >
              {answer?.title}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="ruleQuestion">
        <div className="ruleQuestion_ruleQuestions">
          <div>
            {answerRules !== undefined && answerRules[ruleQuestionIndex] && (
              <>
                <ArrowBackIosNewIcon onClick={() => handleBack()} />

                <Question
                  data={answerRules[ruleQuestionIndex]}
                  onAnswered={handleOnNavigation}
                  fromRuleQuestions={true}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

RuleQuestion.propTypes = {
  data: PropTypes.object.isRequired
};

export default RuleQuestion;
