/** Import react/libraries section **/
import PropTypes from 'prop-types';

/** Import styles section **/
import './SectionNumber.scss';

/** Import resources section **/

const SectionNumber = ({ currentSection, totalSections }) => {
  return (
    <div className="SectionNumber">{currentSection + '/' + totalSections}</div>
  );
};

SectionNumber.propTypes = {
  currentSection: PropTypes.number.isRequired,
  totalSections: PropTypes.number
};

export default SectionNumber;
