/** Import react/libraries section **/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';

/** Import helpers section **/
import { inputChange } from '../../utils/formUtils';
import { isValidEmail } from '../../utils/validations.js';
import { LOGIN_ROUTE } from '../../config/URL_ROUTES';

/** Import styles section **/
import './ChangePasswordStyles.scss';

/** Import resources section **/
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

/** Import component section **/
import ConfirmModal from '../../components/ConfirmModal';
import usePageActions from './hooks/usePageActions';
import MUITextField from '../../components/inputs/MUITextField';

const changePasswordModel = {
  email: '',
  password: '',
  confirm_password: ''
};

const ChangePasswordPage = () => {
  const [password, setPassword] = useState(changePasswordModel);
  const [validForm, setValidForm] = useState(false);

  const { successRequest, requested, loading, changePassword } =
    usePageActions();

  let history = useHistory();

  let pageParams = useParams();
  const token = pageParams.token;

  const handleOnSubmit = (data) => {
    changePassword(password, token);
  };

  useEffect(() => {
    let validEmail = isValidEmail(password.email);
    let validPasswords =
      password?.password && password?.confirm_password
        ? password?.password?.trim() === password?.confirm_password?.trim()
        : false;
    setValidForm(validEmail && validPasswords);
  }, [password]);

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };

  const formView = () => {
    return (
      <>
        <div className="changepassword_container_title">
          Favor de ingresar tu nueva contraseña
        </div>
        <div className="changepassword_container_form">
          <MUITextField
            label="Email"
            id="email"
            name="email"
            type="text"
            value={password?.email}
            onChange={(data) => inputChange(setPassword, data)}
          />
          <MUITextField
            label="Nueva Contraseña"
            id="password"
            name="password"
            type="password"
            value={password?.password}
            onChange={(data) => inputChange(setPassword, data)}
          />
          <MUITextField
            label="Confirmar contraseña"
            id="confirm_password"
            name="confirm_password"
            type="password"
            value={password?.confirm_password}
            onChange={(data) => inputChange(setPassword, data)}
          />
        </div>
        <Button
          className="changepassword_container_button"
          onClick={handleOnSubmit}
          disabled={!validForm}
        >
          {loading === true ? 'Enviando...' : 'Enviar'}
        </Button>
        <div className="changepassword_container_link ">
          <Link to="/">
            <ArrowBackIosIcon /> Regresar a Login
          </Link>
        </div>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <span className="changepassword_container_title error">
          Esta solicitud ya no es valida!
        </span>
      </>
    );
  };

  const viewToShow = () => {
    if (requested && !successRequest) {
      return errorView();
    }

    return formView();
  };

  return (
    <>
      <div className="changepassword">
        <Paper className="changepassword_container">{viewToShow()}</Paper>
      </div>

      {successRequest && (
        <ConfirmModal
          title={'Password successfully changed!'}
          message={'Go to login again.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default ChangePasswordPage;
