/** Import react/libraries section **/
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswers } from '../../store/slices/survey-slice';

/** Import styles section **/
import './TextQuestionStyles.scss';
import MUITextField from '../inputs/MUITextField';

/** Import resources section **/
const TextQuestion = ({ data, fromRuleQuestions }) => {
  let dispatch = useDispatch();
  let surveyAnwers = useSelector(
    (store) => store.survey.answers?.[data.id]?.[0]
  );
  const handleOnInputChange = (data) => {
    const { id, value } = data;

    dispatch(
      setAnswers({
        id: id,
        question_answers: [value?.toString()]
      })
    );
  };

  const textQuestion = surveyAnwers ? surveyAnwers : '';

  return (
    <>
      {!!data?.title && fromRuleQuestions ? (
        <>
          <div className="surveySection_title">{data?.title}</div>
        </>
      ) : (
        <div className="questionTitle">{data?.title}</div>
      )}
      <MUITextField
        id={data?.id}
        name={data?.title}
        type="text"
        value={textQuestion}
        onChange={handleOnInputChange}
      />
    </>
  );
};

TextQuestion.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default TextQuestion;
