/** Import react/libraries section **/
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswers } from '../../store/slices/survey-slice';

/** Import styles section **/
import './NumericQuestionStyles.scss';
import NumericInput from '../NumericInput';

/** Import resources section **/
const NumericQuestion = ({ data }) => {
  let dispatch = useDispatch();
  let surveyAnwer = useSelector((store) => store.survey.answers[data?.id]?.[0]);

  const handleOnInputChange = (value) => {
    dispatch(
      setAnswers({
        id: data?.id,
        question_answers: [value?.toString()]
      })
    );
  };
  const numericAnswer = surveyAnwer ? parseInt(surveyAnwer) : 0;

  return (
    <NumericInput
      numericAnswer={numericAnswer}
      questionTitle={data?.title}
      onInputChange={handleOnInputChange}
    />
  );
  // return (
  //   <div className="numericQuestion">
  //     <div>{data?.title}</div>
  //     <MUITextField
  //       id={data?.id}
  //       name={data?.title}
  //       type="number"
  //       value={numericAnswer}
  //       onChange={handleOnInputChange}
  //       className="numericQuestion_number"
  //     />
  //   </div>
  // );
};

NumericQuestion.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default NumericQuestion;
