/** Import helpers section **/
import { useState } from 'react';

/** Import services section **/
import { changePasswordService } from '../../../api/services/change-password-services';

const useActions = () => {
  const [requested, setRequested] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  const changePassword = (password, token) => {
    setLoading(true);
    let params = {
      email: password.email,
      token: token,
      password: password.password
    };
    changePasswordService(params)
      .then((response) => {
        setSuccessRequest(true);
      })
      .catch((err) => {
        setSuccessRequest(false);
      })
      .finally(() => {
        setRequested(true);
        setLoading(false);
      });
  };

  return {
    successRequest,
    requested,
    loading,
    changePassword
  };
};

export default useActions;
