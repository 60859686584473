/** Import react/libraries section **/
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Import helpers section **/
import { PUBLIC_ROUTES } from '../../config/ROUTES_CONTENT';
import logoMediata from '../../assets/hoteles_calle_1.png';

/** Import styles section **/
import './MainStyles.scss';

/** Import resources section **/

/** Import component section **/
import PrivateLayout from '../PrivateLayout';

const MainComponent = () => {
  let authenticated = useSelector((store) => store.session.authenticated);
  let role = useSelector((store) => store.session.role);

  return (
    <div className="main">
      <BrowserRouter>
        {authenticated && role ? (
          <PrivateLayout />
        ) : (
          <div className="main_public">
            <div className="main_public_header">
              <img
                src={logoMediata}
                alt="htc1-logo"
                className="main_public_header_logo"
              />
            </div>
            <div className="main_public_content">
              <Switch>
                {PUBLIC_ROUTES.map((route, index) => (
                  <Route
                    exact
                    key={index}
                    path={route.path}
                    component={route.component}
                  />
                ))}
                <Redirect to="/" />
              </Switch>
            </div>
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

export default MainComponent;
