/** Import react/libraries section **/
import PropTypes from 'prop-types';

/** Import styles section **/
import './SurveyTitleStyles.scss';

/** Import resources section **/

 const SurveyTitle = ({ title }) => {
  return <div className="surveyTitle">{title}</div>;
};

SurveyTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default SurveyTitle;
