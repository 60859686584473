/** Import react/libraries section **/
import PropTypes from 'prop-types';

/** Import styles section **/
import './QuestionStyles.scss';

/** Import resources section **/

import {
  numericType,
  textType,
  singleType,
  multipleType,
  ruleQuestionType,
  optionLineQuestionType
} from '../../views/RegistrationSurvey/constants';
import TextQuestion from '../TextQuestion';
import MultipleQuestion from '../MultipleQuestion';
import NumericQuestion from '../NumericQuestion';
import SingleQuestion from '../SingleQuestion';
import RuleQuestion from '../RuleQuestion';
import OptionLine from '../OptionLine';

const Question = ({ data, onAnswered, fromRuleQuestions }) => {
  const type = data?.type?.type;

  const renderQuestion = () => {
    switch (type) {
      case textType:
        return (
          <TextQuestion
            data={data}
            onAnswered={onAnswered}
            fromRuleQuestions={fromRuleQuestions}
          />
        );
      case numericType:
        return <NumericQuestion data={data} onAnswered={onAnswered} />;
      case singleType:
        return (
          <SingleQuestion
            data={data}
            onAnswered={onAnswered}
            fromRuleQuestions={fromRuleQuestions}
          />
        );
      case multipleType:
        return <MultipleQuestion data={data} onAnswered={onAnswered} />;
      case ruleQuestionType:
        return <RuleQuestion data={data} />;
      case optionLineQuestionType:
        return <OptionLine data={data} onAnswered={onAnswered} />;
      default:
        return null;
    }
  };

  return <>{renderQuestion()}</>;
};

Question.propTypes = {
  data: PropTypes.object.isRequired
};

export default Question;
