/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { LOGIN_ROUTE } from '../../../config/URL_ROUTES';

/** Import services section **/
import { singupsService } from '../../../api/services/signups-services';

const useActions = () => {
  const [registered, setRegistrationed] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const singUp = useCallback((userData) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    singupsService(userData)
      .then((response) => {
        setRegistrationed(true);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error in registration: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnCancel = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };
  const handleOnConfirmModalClose = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };

  return {
    registered,
    loading,
    singUp,
    handleOnCancel,
    handleOnConfirmModalClose
  };
};

export default useActions;
