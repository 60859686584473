/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

/** Import helpers section **/

/** Import services section **/
import { getRegistrationSurveysService } from '../../../api/services/registration-survey-services';
import { saveAnswersSurvey } from '../../../api/services/survey-services';

const useActions = () => {
  const reservationIdStored = useSelector(
    (store) => store.survey?.reservation_id
  );
  const [surveyData, setSurveyData] = useState({});
  const [answeredSurvey, setAnsweredSurvey] = useState(false);

  const getRegistrationSurvey = useCallback((pageReservationId) => {
    let params = {
      reservation_id: pageReservationId,
      quiz_id: 1 // registration quiz id
    };
    getRegistrationSurveysService(params)
      .then((response) => {
        if (response?.survey) setSurveyData(response.survey);
        if (response?.answered) setAnsweredSurvey(true);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error in registration: ', err);
      });
  }, []);

  const saveSurvey = useCallback(
    (survey, pageReservationId, registrationSurveyId) => {
      saveAnswersSurvey(survey, pageReservationId, registrationSurveyId)
        .then((response) => {
          toast.success('Encuesta guardada correctamente');
          getRegistrationSurvey(reservationIdStored);
        })
        .catch((error) => {
          console.error('no se ha podido guardar la encuesta', error);
        });
    },
    [getRegistrationSurvey,reservationIdStored]
  );

  return {
    surveyData,
    getRegistrationSurvey,
    saveSurvey,
    answeredSurvey
  };
};

export default useActions;
