/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import LoginComponent from '../views/Login';
import SingupsComponent from '../views/Signups';
import EmailValidationComponent from '../views/EmailValidation';
import ChangePasswordRequestComponent from '../views/ChangePasswordRequest';
import ChangePasswordComponent from '../views/ChangePassword';

import UsersComponent from '../views/Users';
import PermissionsComponent from '../views/Permissions';
import RolePermisionsComponent from '../views/RolePermissions';
import RegistrationSurveyPage from '../views/RegistrationSurvey';
import CheckOutSurveyPage from '../views/CheckOutSurvey';
import CheckInSurvey from '../views/CheckInSurvey';
import CheckInSurveyPage from '../views/CheckInSurvey';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: LoginComponent
  },
  {
    path: routes.LOGIN_ROUTE,
    component: LoginComponent
  },
  {
    path: routes.SIGNUPS_ROUTE,
    component: SingupsComponent
  },
  {
    path: routes.EMAIL_VALIDATION_ROUTE + '/:token',
    component: EmailValidationComponent
  },
  {
    path: routes.CHANGE_PASSWORD_REQUEST_ROUTE,
    component: ChangePasswordRequestComponent
  },
  {
    path: routes.CHANGE_PASSWORD_ROUTE + '/:token',
    component: ChangePasswordComponent
  },
  {
    path: routes.REGISTRATION_SURVEY + '/:reservation',
    component: RegistrationSurveyPage
  },
  {
    path:
      routes.CHECK_OUT_SURVEY +
      '/:reservation' +
      '/:reservation_number' +
      '/:client_name' +
      '/:check_in' +
      '/:check_out' +
      '/:rooms_count',
    component: CheckOutSurveyPage
  },
  {
    path: routes.CHECK_IN_SURVEY + '/:reservation',
    component: CheckInSurveyPage
  }
];

export const PRIVATE_ROUTES = [
  {
    path: routes.USERS_ROUTE,
    component: UsersComponent
  },
  {
    path: '/',
    component: UsersComponent
  },
  {
    path: routes.PERMISSIONS_ROUTE,
    component: PermissionsComponent
  },
  {
    path: routes.ROLE_PERMISSIONS_ROUTE,
    component: RolePermisionsComponent
  }
];
