/** Import react/libraries section **/
import { useState } from 'react';
import PropTypes from 'prop-types';

/** Import styles section **/
import './SurveyStyles.scss';
import SurveySection from '../SurveySection';
import SectionNumber from '../SectionNumber';
import SurveyTitle from '../SurveyTitle';
import { Button } from '@mui/material';

/** Import resources section **/

const Survey = ({
  data,
  onSave,
  reservationData = null,
  checkOutSurvey = false
}) => {
  const [selectedSection, setSelectedSection] = useState(0);

  const sectionIndex = selectedSection;
  const totalSections = data?.quiz_sections?.length || 0;
  const currentSection = selectedSection + 1;

  const surveySection = data?.quiz_sections?.[sectionIndex] || {};
  const surveyTitle = data?.title || 'Encuesta';

  const isLastSection = currentSection === totalSections;
  const isFirstSection = sectionIndex === 0;

  const handleOnTabChange = () => {
    if (selectedSection === 2) return;
    let changeCurrentTab = selectedSection + 1;
    setSelectedSection(changeCurrentTab);
  };

  const handleOnSubTab = () => {
    if (selectedSection === 0) return;
    let changeCurrentTab = selectedSection - 1;
    setSelectedSection(changeCurrentTab);
  };
  return (
    <div className="survey">
      <div className="survey_title">
        <SurveyTitle title={surveyTitle} />
      </div>
      <div className="survey_content">
        <SectionNumber
          currentSection={currentSection}
          totalSections={totalSections}
        />
        {checkOutSurvey && (
          <div className="checkOutData">
            <div>Nombre: {reservationData?.name || ''} </div>
            <div>
              Numero de reservacion: {reservationData?.reservation_number || ''}
            </div>
            <div>Telefono: {reservationData?.phone || ''} </div>
            <div>No. Habitacion: {reservationData?.rooms || ''} </div>
            <div>Estancia: {reservationData?.stay_dates || ''} </div>
            <div>Motivo de viaje: {reservationData?.reason_trip || ''} </div>
          </div>
        )}
        <SurveySection data={surveySection} />

        <div className="survey_navigationButtons">
          {!isFirstSection && (
            <Button onClick={handleOnSubTab} type="submit" disabled={false}>
              Atras
            </Button>
          )}
          {!isLastSection ? (
            <Button onClick={handleOnTabChange} type="submit" disabled={false}>
              Siguiente
            </Button>
          ) : (
            <Button onClick={onSave} type="submit" disabled={false}>
              Enviar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Survey.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default Survey;
