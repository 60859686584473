import request from '../api-connector';

export const singupsService = ({
  school_id,
  email,
  first_name,
  last_name,
  password,
  password_confirmation
}) => {
  const serviceDef = {
    url: 'register',
    method: 'post',
    params: {
      school_id,
      email,
      first_name,
      last_name,
      password,
      password_confirmation
    }
  };
  return request(serviceDef);
};

export const emailValidationService = ({ token }) => {
  const serviceDef = {
    url: 'email/verify',
    method: 'post',
    params: { token }
  };
  return request(serviceDef);
};
