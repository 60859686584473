/** Import helpers section **/
import { toast } from 'react-toastify';

import {
  addUserService,
  updateUserService,
  changePasswordService
} from '../../../../api/services/users-services';

const useModalActions = () => {
  const createUser = (user, onClose, onUpdateList) => {
    addUserService(user)
      .then((response) => {
        toast.success('User created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('User create error');
      });
  };

  const updateUser = (user, onClose, onUpdateList) => {
    updateUserService(user)
      .then(() => {
        toast.success('User updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('User update error');
      });
  };

  const changePassword = (user, onClose, onUpdateList) => {
    changePasswordService(user)
      .then(() => {
        toast.success('User updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('User update error');
      });
  };

  return {
    createUser,
    updateUser,
    changePassword
  };
};

export default useModalActions;
