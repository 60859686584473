/** Import react/libraries section **/
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswers } from '../../store/slices/survey-slice';

/** Import styles section **/
import './OptionLineStyles.scss';
import MUITextField from '../inputs/MUITextField';

/** Import resources section **/
const OptionLine = ({ data }) => {
  let dispatch = useDispatch();
  let surveyAnwer = useSelector((store) => store.survey.answers[data?.id]?.[0]);
  let subQuestionAnswer = useSelector(
    (store) => store.survey.answers[data?.sub_questions[0]?.id]?.[0]
  );

  const handleOnInputChange = (value) => {
    dispatch(
      setAnswers({
        id: data?.id,
        question_answers: [value?.toString()]
      })
    );
  };

  const handleOnTextQuestionChange = (data) => {
    console.log(data);
    const { id, value } = data;

    dispatch(
      setAnswers({
        id: id,
        question_answers: [value?.toString()]
      })
    );
  };

  const optionLineAnswerId = surveyAnwer ? parseInt(surveyAnwer) : null;

  return (
    <div className="optionLine">
      <div className="optionLine_title">{data?.title}</div>
      <div className="optionLine_options">
        {data?.answers.map((answer, index) => (
          <div
            key={'optionLine_option_' + data?.id + '_' + index}
            onClick={() => handleOnInputChange(answer?.id)}
            className={
              optionLineAnswerId === parseInt(answer?.id)
                ? 'optionLine_options_selected'
                : 'optionLine_options_option'
            }
          >
            {answer?.title}
          </div>
        ))}
      </div>
      {data?.sub_questions.map(
        (subQuestion, index) =>
          subQuestion?.rule_answer_id === optionLineAnswerId && (
            <>
              <div
                className="textQuestionTitle"
                key={
                  'sub_question_' +
                  toString(subQuestion?.id) +
                  '_' +
                  toString(index)
                }
              >
                {subQuestion?.title}
              </div>
              <div
                className="textQuestionTitle_inputText"
                key={
                  'input_sub_question_' +
                  toString(subQuestion?.id) +
                  '_' +
                  toString(index)
                }
              >
                <MUITextField
                  id={subQuestion?.id}
                  name={subQuestion?.title}
                  type="text"
                  value={subQuestionAnswer}
                  onChange={handleOnTextQuestionChange}
                />
              </div>
            </>
          )
      )}
    </div>
  );
};

OptionLine.propTypes = {
  data: PropTypes.object.isRequired
};

export default OptionLine;
