import { LOCAL_STORAGE_PREFIX } from '../config/APP_CONFIGS';
import asynkstorage from 'asynkstorage';

const localStoragePrefix = LOCAL_STORAGE_PREFIX + '@';

export const getLocalStorage = (name) =>
  new Promise((resolve, reject) => {
    asynkstorage
      .getItem(localStoragePrefix + name)
      .then((answers) => {
        // let ans = JSON.parse(answers);
        return resolve(answers);
      })
      .catch((e) => {
        return reject(e);
      });
  });

export const saveLocalStorage = (name, obj) => {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem(localStoragePrefix + name, JSON.stringify(obj))
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeProfile error: ', e.message);
        return reject(false);
      });
  });
  return response;
};

export async function clearLocalStored(name) {
  console.log(localStoragePrefix + name);
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .removeItem(localStoragePrefix + name)
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        // catch token
        return resolve(false);
      });
  });
  return response;
}
