/** Import helpers section **/
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

/** Import services section **/
import { loginService } from '../../../api/services/session-services';
import { setSession } from '../../../store/slices/session-slice';
import { setPermissions } from '../../../store/slices/permissions-slice';

const usePageActions = () => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let loggedIn = useSelector((store) => store.session.loggedIn);

  const login = ({ username, password }) => {
    setLoading(true);
    loginService({ username, password })
      .then((response) => {
        let permissions = response?.permissions;
        const actions = permissions?.actions;
        const routes = permissions?.routes;
        const menus = permissions?.menus;

        setLoading(false);
        dispatch(setPermissions({ actions, routes, menus }));

        let sessionInfo = {
          token: response?.token,
          role: response?.role,
          profile: {
            name: response?.first_name + ' ' + response?.last_name
          }
        };
        dispatch(setSession(sessionInfo));
      })
      .catch((err) => {
        setLoading(false);
        const status = err.response_status;

        let message = '';
        switch (status) {
          case 401:
            message = 'Invalid user or password.';
            break;
          case 422:
            message =
              'This user must be authorized. Please contact the administrator.';
            break;
          default:
            message = "Unexpected error, can't login";
            break;
        }

        if (status) toast.error(message);
        console.error('Error login: ', err);
      });
  };

  return {
    loading,
    loggedIn,
    login
  };
};

export default usePageActions;
